import React from 'react';
import moment from 'moment';
import BaseTable from '../BaseTable';
import {
  formatDate,
  formatPrice,
  FORMAT_HUMANE_DATETIME,
  renderLink,
  __,
  LabelComp,
} from '../../Utils';
import { searchContacts } from '../../Utils/ApiSearchFunctions';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import tableHeaders from '../../ContentConfig/billsList';
// import orderStates from '../../ContentConfig/order_states';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import { StyledA } from '../../Components/ReusableComponents';
import API2 from '../../API2';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';
import styled from 'styled-components';
import { rem } from 'polished';
import { Label } from 'oxyrion-ui/lib';
import OwnStateInput from '../../Components/OwnStateInput';
import FinPortalDetail from '../../Components/FinPortalDetail';
import ReceiptEdit from '../../Components/ReceiptEdit';

const InputWrapper = styled.div`
  width: 96%;
`;
const HeaderLabel = styled.div`
  background: #f8f1f8;
  height: ${rem(46)};
  color: #a448a3;
  /* padding-left: ${rem(16)}; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: ${rem(12)};

  button{
    padding: ${rem(10)} ${rem(20)};
    margin-right: ${rem(12)};
    background-color: ${props => props.theme.color.primary};
    color: white;
    cursor: pointer;
    border: none;
    border-radius: ${rem(12)};
  }
`;

const paymentsTypes = [
  {
    name: __('Hotovosť'),
    value: 'HOTOVOST',
  },
  {
    name: __('Karta'),
    value: 'KARTA',
  },
  {
    name: __('Kombinovaná'),
    value: 'KOMBINOVANA',
  },
];

const paymentsStatus = [
  {
    name: __('Predaj'),
    value: 'PREDAJ',
  },
  {
    name: __('Storno'),
    value: 'STORNO',
  },
];

const statusTypes = [
  {
    name: __('Navision OK'),
    value: 'NAV_OK',
  },
  {
    name: __('Navision FAIL'),
    value: 'NAV_FAIL',
  },
];

class BillsList extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: '-createdDate' });
    this.headers = tableHeaders;
    this.segmentName = __('Predajné doklady');
    this.accessors = [
      'id',
      'cr_id',
      'customer',
      'price_VAT',
      'price',
      'created_date',
      'payment_status',
      'payment_type',
      'status',
    ];
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.state = {
      ...this.state,
      showFilter: false,
      activeMessage: 'Všetky',
      sorter: '-createdDate',
      filters: [
        // {
        //   name: 'shopId',
        //   label: __('Shop Id'),
        //   value: '',
        //   type: 'input',
        // },
        {
          name: 'payments',
          label: __('Stav'),
          value: '',
          values: paymentsTypes,
          type: 'select',
        },
        {
          name: 'contactId',
          label: __('Zákazník'),
          value: '',
          type: 'search-select',
          onSearch: q => searchContacts(q, this.props.firm),
        },
        {
          name: 'status',
          label: __('Stav navision'),
          value: '',
          values: statusTypes,
          type: 'select',
        },
        {
          name: 'fromDate',
          label: __('Dátum od'),
          value: '',
          type: 'date',
        },
        {
          name: 'toDate',
          label: __('Dátum do'),
          value: '',
          type: 'date',
        },
      ],
      searchReceiptLoading: false,
      searchReceiptId: '',
      showFinDetailPopUp: false,
      showEditReceiptPopUp: false,
      finData: {
        resultFinPortalInfo: [],
        resultFinPortalAdditional: [],
      },
    };
  }

  async fetchData(parameters = {}) {
    try {
      this.setState({ loading: true });
      const { sorter, selectedCustomer, filters } = this.state;

      const filterToSend = {};

      filters
        .filter(f => f.value && f.value !== '-')
        .forEach(f => {
          if (f.type === 'date') {
            filterToSend[f.name] = new Date(f.value).toISOString();
          } else if (f.type === 'search-select') {
            filterToSend[f.name] = f.value.value;
          } else {
            filterToSend[f.name] = f.value;
          }
        });

      const params = Object.assign({}, filterToSend, parameters, { sorter });

      if (selectedCustomer) {
        params.customerId = selectedCustomer.value;
      }

      const newData = await API2.getKasaBillsAction(this.props.firm, params);
      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  applyFilter() {
    this.fetchData();
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizePaymentType(payments) {
    if (payments.some(payment => payment.code === 'KARTA')) {
      if (payments.every(payment => payment.amount > 0)) {
        return paymentsTypes[2].name;
      } else {
        return paymentsTypes[1].name;
      }
    } else {
      return paymentsTypes[0].name;
    }
  }

  normalizePaymentStatus(price) {
    if (price && price.amount > 0) {
      return (
        <LabelComp background="#06D6A0">{paymentsStatus[0].name}</LabelComp>
      );
    } else {
      return (
        <LabelComp background="#EF476F">{paymentsStatus[1].name}</LabelComp>
      );
    }
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        _id: item._id,
        id: _shapeData(
          renderLink(
            `./bills/${item._id}`,
            item.data_nav ? item.data_nav._id : item.data.description,
          ),
        ),
        cr_id: _shapeData(item.cr ? item.cr._id : __('Neznáma')),
        price_VAT: _shapeData(formatPrice(item.totalPrice)),
        price: _shapeData(formatPrice(item.totalPriceVat)),
        payment_code: _shapeData(
          item.data.payments[0] && item.data.payments[0].code,
        ),
        payment_type: _shapeData(this.normalizePaymentType(item.data.payments)),
        payment_status: _shapeData(
          this.normalizePaymentStatus(item.totalPriceVat),
        ),
        customer: _shapeData(
          item.contact && item.data.contact_id ? (
            <StyledA
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.REACT_APP_CRM_BASE_URL}/contacts/${item.data.contact_id}`}
            >
              {`${item.contact.name} ${item.contact.surname}`}
            </StyledA>
          ) : (
            `-`
          ),
        ),
        created_date: _shapeData(
          formatDate(moment(item.createdDate), FORMAT_HUMANE_DATETIME),
        ),
        status: _shapeData(
          item.data_nav ? __('Zaučtovaná v navision') : __('Chyba'),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  handleOnSearchFinPortal() {
    const { searchReceiptId } = this.state;
    if (searchReceiptId && searchReceiptId.length > 0) {
      this.setState({
        showFinPopUp: false,
        showFinDetailPopUp: true,
      });
    }
  }

  handleNavSend() {
    // const { finData } = this.state;
    // let errors = false;

    // finData.items
    //   .filter(f => f.itemType !== 'Z')
    //   .foreach(e => {
    //     if (!e.findProduct) {
    //       errors = true;
    //     }
    //   });

    // if (!errors) {
    this.setState({
      showEditReceiptPopUp: true,
    });
    //}
  }

  async createNewBill(data) {
    const items = [];

    try {
      for (let index = 0; index < data.items.length; index++) {
        const i = data.items[index];
        let discountItem = null;

        if (data.items[index + 1] && data.items[index + 1].itemType === 'Z') {
          discountItem = data.items[index + 1];
          index++;
        }

        items.push({
          code: i.findProduct.value,
          discount: discountItem ? Math.abs(Number(discountItem.price)) : 0,
          name: i.name,
          price: i.price,
          quantity: i.quantity,
          stock: data.warehouse.value,
          totalPrice:
            i.price - (discountItem ? Math.abs(Number(discountItem.price)) : 0),
          type: 'Item',
          unitPrice: i.price / i.quantity,
          vat: i.vatRate,
        });
      }

      const body = {
        cr_id: data.cr.value,
        contact_id: '',
        createdDate: moment(data.createDate, 'DD-MM-YYYY hh:mm:ss'),
        paidDate: moment(data.issueDate, 'DD-MM-YYYY hh:mm:ss'),
        description: data.receiptId,
        items: items,
        payments: [{ amount: data.totalPrice, code: data.payment_code.value }],
      };

      await API2.createKasaBillFinPortalAction({ body });

      this.setState({
        success: __('Doklad bol úspešne odoslaný do navision'),
        showEditReceiptPopUp: false,
        showFinDetailPopUp: false,
        showFinPopUp: false,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri odosielanii do navision sa vyskytla chyba'),
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    const { showFilter } = this.state;

    return (
      <ControllBar
        history={history}
        name={__('Predajné doklady')}
        // onChange={val => this.loadSearchRrsult(val)}
      >
        <ControlBarButton
          onClick={() => this.setState({ showFinPopUp: true })}
          showIcon={false}
          name="nav_receipt_send"
          small
          primary
          style={{ 'margin-right': '5px ' }}
        >
          {__('Odoslať extra doklad do navision')}
        </ControlBarButton>
        <ControlBarButton
          onClick={() =>
            this.setState({
              showFilter: !showFilter,
            })
          }
          icon={showFilter ? 'arrow-right' : 'arrow-down'}
          name="show_filter"
        >
          {showFilter ? __('Schovať filtre') : __('Zobraziť filtre')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    const {
      showFinPopUp,
      searchReceiptId,
      searchReceiptLoading,
      showFinDetailPopUp,
      showEditReceiptPopUp,
      finData,
    } = this.state;

    return (
      <React.Fragment>
        {super.render()}
        <PopUpWrapper
          display={showFinPopUp}
          onClose={() => this.setState({ showFinPopUp: false })}
          small
          onEscClose={() => this.setState({ showFinPopUp: false })}
          message={__('Najsť doklad vo finančnej správe?')}
          scroll={false}
        >
          <ConfirmDialog
            onConfirm={() => this.setState({ showFinPopUp: false })}
            onDismiss={() => this.handleOnSearchFinPortal()}
            dismissLoading={searchReceiptLoading}
            confirmMessage={__('Zrušiť')}
            dismissMessage={__('Hľadaj')}
            message={
              <div>
                <InputWrapper>
                  <Label>{__('ID dokladu')}</Label>
                  <OwnStateInput
                    onBlur={e =>
                      this.setState({ searchReceiptId: e.target.value })
                    }
                    placeholder={'O-4A4D92C9542D4A0C8D92C9542D0A0CB9'}
                    value={searchReceiptId}
                  />
                </InputWrapper>
              </div>
            }
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showFinDetailPopUp}
          onClose={() => this.setState({ showFinDetailPopUp: false })}
          onEscClose={() => this.setState({ showFinDetailPopUp: false })}
          message={__('Informácie o doklade z finančnej správy')}
        >
          <FinPortalDetail
            functionalityFinPortalBill
            billId={searchReceiptId}
            onLoading={loading =>
              this.setState({ searchReceiptLoading: loading })
            }
            onError={e => this.setState({ showFinDetailPopUp: false })}
            onSuccess={data =>
              this.setState({
                finData: data.receipt,
              })
            }
            header={
              <HeaderLabel>
                <button onClick={() => this.handleNavSend()}>
                  {__('Odoslať do navision')}
                </button>
              </HeaderLabel>
            }
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showEditReceiptPopUp}
          onClose={() => this.setState({ showEditReceiptPopUp: false })}
          small
          onEscClose={() => this.setState({ showEditReceiptPopUp: false })}
          message={__('Editácia dokladu pred odoslaním do NAV')}
          scroll={false}
        >
          <ReceiptEdit
            receiptData={finData}
            onDismiss={() => this.setState({ showEditReceiptPopUp: false })}
            onConfirm={data => this.createNewBill(data)}
          />
        </PopUpWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(BillsList);
